<template>
  <header>
    <div class="topbar">
      <div class="container top">
        <div class="left">
          <div class="phone">
            <i class="fa fa-phone"></i>
            {{ config?.district_phone?.description }}
          </div>
          <div class="email">
            <i class="fas fa-mail-bulk"></i>
            {{ config?.district_email?.description }}
          </div>
        </div>
        <div class="right">
          <div class="social__icons">
            <span class="facebook">
              <a target="_blank" href="https://www.facebook.com/LDC325JNEPAL">
                <i class="fab fa-facebook"></i></a>
            </span>
            <!-- <span class="twitter">
              <a href="#"> <i class="fab fa-twitter"></i></a>
            </span>
            <span class="linkedin">
              <a href="#"> <i class="fab fa-linkedin"></i></a>
            </span>
            <span class="instagram">
              <a href="#"> <i class="fab fa-instagram"></i></a>
            </span> -->
          </div>
        </div>
      </div>
    </div>
    <!-- place navbar here -->
    <nav class="navbar navbar-expand-xl navbar-light bg-white shadow">
      <div class="container">
        <router-link class="navbar-brand d-flex justify-content-center gap-2 align-items-center" to="/">
          <img src="/images/logos/current/logo.png" alt="" width="40" height="40" />
          <span class="h3">Leo District Council 325 J Nepal</span>
        </router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0 gap-4">
            <!-- <li class="nav-item">
              <router-link class="nav-link active" aria-current="page" to="/">Home</router-link>
            </li> -->
            <li class="nav-item">
              <router-link to="/about" class="nav-link">About</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/gallery" class="nav-link">Gallery</router-link>
            </li>

            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                Resources
              </a>

              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <router-link to="/leo-resources" class="dropdown-item" href="#">Leo Resources</router-link>
                </li>

                <li>
                  <router-link to="/blood-database" class="dropdown-item" href="#">
                    Blood Group Database
                  </router-link>
                </li>
                <li>
                  <router-link to="/our-affiliations" class="dropdown-item" href="#">
                    Our Affiliations
                  </router-link>
                </li>
                <li>
                  <router-link to="/district-directory" class="dropdown-item" href="#">
                    District Directory
                  </router-link>
                </li>
                <li>
                  <router-link to="/development-teams" class="dropdown-item" href="#">
                    Development Team
                  </router-link>
                </li>
                <li>
                  <router-link to="/leo-foundation" class="dropdown-item" href="#">
                    Nepal Leo Foundation
                  </router-link>
                </li>
                <li>
                  <router-link to="/nlf-membership" class="dropdown-item" href="#">
                    NLF Membership
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                Events & Calendar
              </a>

              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <router-link class="dropdown-item" to="/events">Events</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/calendar">Calendar</router-link>
                </li>
              </ul>
            </li>

            <li class="nav-item">
              <router-link class="nav-link" to="/teams" tabindex="-1" aria-disabled="true">Team</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/clubs" tabindex="-1" aria-disabled="true">Clubs</router-link>
            </li>

            <li class="nav-item">
              <router-link class="nav-link" href="#" to="contact" tabindex="-1" aria-disabled="true">Contact
              </router-link>
            </li>
            <div>
              <router-link to="/donate" name="" id="" class="btn btn-primary donate-button border-0" href="#"
                role="button">Donate</router-link>
            </div>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters({
      config: 'getConfig'
    })
  },
  data() {
    return {
      show: true,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path == "/" || from.path == "/") {
        document.querySelector("#navbarSupportedContent").classList.remove("show");
        document.querySelector(".navbar-toggler").classList.add("collapsed");
      } else {
        document.querySelector("#navbarSupportedContent").classList.toggle("show");
        document.querySelector(".navbar-toggler").classList.toggle("collapsed");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$topbar_color: #5a97c1;

.donate-button {
  background-color: $topbar_color;

  &:hover {
    background-color: #ff6600;
  }
}

$breakpoint-tablet: 768px;

@media (max-width: $breakpoint-tablet) {
  .donate-button {
    background-color: $topbar_color;
  }

  #navbarSupportedContent {
    padding: 10px 0px;

    ul li {
      // border: 1px solid #223;
      padding: 0px 10px;
    }
  }
}

.navbar {
  padding: 10px 0px;

  .navbar-brand {
    .h3 {
      font-family: "Dosis";
      font-size: 1.1em;
      font-weight: 700;
      align-self: center;
      padding-top: 15px;
    }

    img {
      display: none;
    }
  }

  .dropdown-menu {
    width: 300px;
    margin-top: 30px;
    border: 0px;
  }

  .dropdown-item {
    padding: 10px 10px;

    // font-size: 1.2em;
    &:hover {
      transition: all 0.5s ease-in-out;
      border-bottom: 2px solid #ff6600;
      background-color: transparent;
    }
  }

  .nav-link {
    font-size: 1em;

    &:hover {
      transition: all 0.5s ease-in-out;
      border-bottom: 2px solid #ff6600;
    }
  }
}

@media screen and (min-width: 800px) {
  .topbar {
    background-color: $topbar_color;
    padding: 10px 0px;

    color: $text_color;

    .top {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: center;
        align-items: center;
        font-size: 0.9em;
      }

      .right {
        display: flex;
        align-items: center;

        .social__icons {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          font-size: 1.2em;
        }

        a {
          text-decoration: none;
          color: #fff;
        }

        i {
          transition: all 0.4s ease-in-out;

          &:hover {
            font-size: 1.2em;
          }
        }
      }
    }
  }
}

/* SHIFT */
nav.stroke ul li a,
nav.fill ul li a {
  position: relative;
}

nav.stroke ul li a:after,
nav.fill ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  color: transparent;
  background: #aaa;
  height: 1px;
}

nav.stroke ul li a:hover:after {
  width: 100%;
}

nav.fill ul li a {
  transition: all 2s;
}

nav.fill ul li a:after {
  text-align: left;
  content: ".";
  margin: 0;
  opacity: 0;
}

nav.fill ul li a:hover {
  color: #fff;
  z-index: 1;
}

nav.fill ul li a:hover:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}

@media screen and (max-width: 800px) {
  .topbar {
    background-color: $topbar_color;
    padding: 10px 0px;

    color: $text_color;
    display: none;

    .top {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 0.9em;
      }

      .right {
        display: flex;
        align-items: center;

        .social__icons {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          font-size: 1.2em;
        }

        a {
          text-decoration: none;
          color: #fff;
        }

        i {
          transition: all 0.4s ease-in-out;

          &:hover {
            font-size: 1.2em;
          }
        }
      }
    }
  }
}
</style>