<template>
  <section class="projects">
    <div class="container">
      <div class="title">Proudly Be a Leo</div>
      <div class="subtitle">
        Be a Leader ! Develop experience ! Lots of opportunity comes to your
        door !!!
      </div>
      <div class="works">
        <div class="items">
          <div class="item">
            <div class="icon">
              <img
                src="https://cdn4.iconfinder.com/data/icons/business-pack-7/64/performance-management-action-forward-leaadership-leader-512.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">Leadership</div>
            <div class="subtitle">
              Leo is a open forum to develop social leaders. Service the society
              in a organized youth participation makes you a good leader.
              Service in a designation for a single year makes you able for
              varieties of responsibility and leadership
            </div>
          </div>
          <div class="item">
            <div class="icon">
              <img
                src="https://cdn3.iconfinder.com/data/icons/elections-flat-colorful/2048/4552_-_Award-512.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">Experience</div>
            <div class="subtitle">
              Leo provides you varieties of experience regarding personal growth
              and skills. It is a open forum to develop and enhance various
              attribute of life long experiences.
            </div>
          </div>
          <div class="item">
            <div class="icon">
              <img
                src="https://cdn2.iconfinder.com/data/icons/flat-ii-1/289/Flat_Elements-33-512.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">Opportunity</div>
            <div class="subtitle">
              Lots of Doors for opportunity are always open for social leader
              with life bearing experience and Leadership
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
<style lang="scss">
.projects {
  padding: 50px 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .title {
    text-transform: uppercase;
    font-size: 2em;
    font-weight: bold;
  }

  .works {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 50px;

    .items {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 100px;
      flex-wrap: wrap;

      .item {
        max-width: 22rem;

        .icon {
          color: #cc0033;

          img {
            width: 100px;
          }

          &:hover {
            animation: shake 0.3s;
            animation-iteration-count: 1;
            color: #00bfff;
          }
        }

        .title {
          font-size: 1.5em;
          font-weight: 400;
        }

        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
</style>
