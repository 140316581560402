<template>
  <section class="mission_vision">
    <div class="container">
      <div class="content">
        <div class="mission card shadow-lg">
          <div class="card-title h4">Our mission</div>
          <ul>
            <li v-for="item in config?.mission?.description.split('\r\n')" :key="item">
              {{ item }}
            </li>
          </ul>
        </div>

        <div class="vision card shadow-lg">
          <div class="card-title h4">Our vision</div>
          <ul>
            <li v-for="item in config?.vision?.description.split('\r\n')" :key="item">
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="works card shadow-lg">
          <div class="card-title h4">Our works</div>
          <ul>
            <li v-for="item in config?.our_works?.description.split('\r\n')" :key="item">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters({
      config: 'getConfig'
    })
  }
}
</script>

<style lang="scss">
.mission_vision {
  background-color: #eee;
  padding: 50px 0px;

  .card {
    min-width: 300px;
    width: 30%;

    &:hover {
      animation: shake 0.3s;
      animation-iteration-count: 1;
    }
  }

  .content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;

    gap: 30px;

    img {
      width: 100px;
      height: 100px;
    }

    .mission {
      padding: 30px;
    }

    .vision,
    .works {
      padding: 30px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .content {
      display: flex;
      justify-content: center;
    }

    .card {
      min-width: 300px;
      width: 90%;
    }
  }
}
</style>