const constants = {
    sortedDesignations: [
        "District President",
        "Leo Club Chairperson",
        "Immediate Past District President",
        "Vice District President",
        "District Secretary",
        "District Treasurer",
        "District Joint Secretary",
        "District Joint Treasurer",
        "District PRO",
        "District Tamer",
        "District Tail Twister",
        "District Administrator",
        "District Spokesperson",
        "Chief District Advisor",
        "District Advisor",
        "Chief District President Advisor",
        "District President Advisor",
        'District Director',
        "GMT Coordinator",
        "GST Coordinator",
        "GLT Coordinator",
        "NLF Chairperson",
        "NLF Management",
        "Chief Committee Chairperson",
        "IT, Communication & Twinning head",
        "Lead With Smile",
        "Entrepreneurs Head",
        "Blood Department Head",
        "ECA Head",
        "Sport Head",
        "Chief Region Coordinator",
        "Region Coordinator",
        "Women Empowerment Chief",
        "Training Pool Coordinator",
        "Training Pool Secretary",
        "Training Pool Member",
        "International Director"
    ],
    developers: [
        {
            photo: "https://sabinkhanal.com.np/sabin.jpg",
            name: "Sabin Khanal",
            role: "Lead Developer",
            website: "https://sabinkhanal.com.np",
        },
        {
            photo:
                "https://admin.leodistrictcouncil325jnepal.org.np/wp-content/uploads/2022/11/Sangit-scaled-e1667386633312.jpg",
            name: "Sangit Shrestha",
            role: "Data Entry",
        },
    ],
};
export default constants;
