<template>
  <section class="footer section-padding">
    <div class="items container">
      <div class="item">
        <div class="title">CONTACT US</div>
        <div class="contents">
          <ul>
            <li>{{ config.district_address?.description }}</li>
            <li>{{ config.district_name?.description }}</li>
            <li>
              <strong>Phone : {{ config.district_phone?.description }} </strong>
            </li>
            <li>Email : {{ config.district_email?.description }}</li>
          </ul>
        </div>
      </div>
      <div class="item">
        <div class="title">Calendar</div>
        <div class="content">
          <iframe src="https://www.hamropatro.com/widgets/calender-medium.php" frameborder="0" scrolling="no"
            marginwidth="0" marginheight="0" style="border: none; overflow: hidden; width: 300px; height: 290px"
            allowtransparency="true"></iframe>
        </div>
      </div>

      <div class="item">
        <div class="title">Quick Links</div>
        <div class="contents">
          <ul>
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/about">About</router-link></li>
            <li><router-link to="/gallery">Gallery</router-link></li>
            <li><router-link to="/clubs">Clubs</router-link></li>
            <li><router-link to="/Contact">Contact</router-link></li>
          </ul>
        </div>
      </div>
      <div class="item">
        <div class="title">Social Links</div>
        <div class="social_icons">
          <a href="#"> <i class="fab fa-facebook"></i></a>
          <!-- <a href="#"> <i class="fab fa-twitter"></i></a>
          <a href="#"> <i class="fab fa-linkedin"></i></a>
          <a href="#"> <i class="fab fa-instagram"></i></a> -->
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters({
      config: 'getConfig'
    })
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 800px) {
  .item {
    max-width: 100%;
    // text-align: left;
  }
}

.footer {
  background-color: #418dc0;

  .items {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: space-around;

    .item {
      display: flex;
      flex-direction: column;

      .title {
        color: #fff;
        font-size: $medium;
        padding: 30px 0px 30px 0px;
        text-transform: uppercase;
        justify-content: left;
        align-items: flex-start;
      }

      .contents {
        display: flex;
        flex-direction: column;
        gap: 20px;
        // align-items: flex-start;
        color: #fafafa;

        // font-size: 1.2em;
        ul {
          list-style: none;
          padding: 0px;

          li {
            margin: 10px;

            a {
              color: #fff;
            }
          }
        }
      }

      .social_icons {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: center;
        color: #fff;

        i {
          color: #fff;
          font-size: 30px;
        }
      }
    }
  }
}
</style>