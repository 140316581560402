import { fetchACF, fetchContent, fetchContentModern } from "@/helpers/api";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    preference: {},
    leaders: [],
    events: [],
    contents: [],
    gallery: [],
    calendar: [],
    blood_group: [],
    clubs: [],
    messages: [],
    config: {},
    galleries: [],
  },

  mutations: {
    updateContent(state, { key, value }) {
      state[key] = value;
    },

    setConfig(state, config) {
      state.config = config;
    },
  },

  actions: {
    async fetchContent({ commit }, key) {
      try {
        const data = await fetchContent(key);
        commit("updateContent", { key, value: data });
      } catch (error) {
        console.error(`Error fetching content for key ${key}:`, error);
      }
    },

    async fetchConfig({ commit }) {
      try {
        const data = await fetchContentModern("contents");

        const keyValueObject = data.reduce((acc, item) => {
          acc[item.key] = item;
          return acc;
        }, {});

        commit("setConfig", keyValueObject);
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    },

    async fetchContentModern({ commit }, key) {
      try {
        const data = await fetchContentModern(key);
        commit("updateContent", { key, value: data });
      } catch (error) {
        console.error(`Error fetching modern content for key ${key}:`, error);
      }
    },
  },

  getters: {
    // Retrieve specific config from the state
    getConfig: (state) => state.config,

    // Retrieve a specific content from the state by key
    getContent: (state) => (key) => state[key],

    // Retrieve galleries from the state
    getGalleries: (state) => state.galleries,
  },

  modules: {},
});
