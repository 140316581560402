<template>
  <div class="home">

    <MainSlider />
    <WhoWeAre />
    <MissionVision />
    <Stats />
    <ProudlyBeALeo />
    <JoinUs />
    <AnimatedSlogan />
    <div>
      <MessageFromDGVDG />
    </div>
    <CoreTeam />
  </div>
</template>

<script>
import MainSlider from "../components/homepage/MainSlider.vue";
import WhoWeAre from "../components/homepage/WhoWeAre.vue";
import MissionVision from "../components/homepage/MissionVision.vue";
import Stats from "../components/homepage/Stats.vue";
import ProudlyBeALeo from "../components/homepage/ProudlyBeALeo.vue";
import JoinUs from "../components/homepage/JoinWithUs.vue";
import AnimatedSlogan from "../components/homepage/AnimatedSlogan.vue";
import MessageFromDGVDG from "../components/homepage/MessageFromDGVDG.vue";
import CoreTeam from "../components/homepage/CoreTeam.vue";


export default {
  name: "Home",
  components: {
    WhoWeAre,
    MissionVision,
    Stats,
    ProudlyBeALeo,
    JoinUs,
    AnimatedSlogan,
    MessageFromDGVDG,
    MainSlider,
    CoreTeam,
  },
};
</script>