import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from "vuex";
import VueSilentbox from 'vue-silentbox'

Vue.use(VueSilentbox)

import VueExpandableImage from 'vue-expandable-image'
Vue.use(VueExpandableImage)
import DataTable from "@andresouzaabreu/vue-data-table";
Vue.component("data-table", DataTable);

import "@andresouzaabreu/vue-data-table/dist/DataTable.css";

Vue.config.productionTip = false

Vue.use(Vuex)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
