<template>
  <section class="bottom-bar">
    <div class="copyright">Copyright &copy; {{ currentYear }}, All rights reserved / Version 3</div>
  </section>
</template>

<script>
export default {
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>

<style lang="scss" scoped>
.bottom-bar {
  display: flex;
  justify-content: center;
  color: #fff;
  background-color: #236998;
  padding: 20px 0px;
}
</style>
