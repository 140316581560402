import { URLS } from "@/constants/urls";
import axios from "axios";

const API_URL=process.env.VUE_APP_API_URL||'https://admin.leodistrictcouncil325jnepal.org.np/wp-json/dm/v1/data/';

export const fetchACF = (data) => {
  return data?.[0]?.acf || {};
};

export const parseACF = (acfArray) => {
  let respData = acfArray
    .map((x) => {
      return { ...(x["acf"] ?? {}), id: x["id"] ?? -1 };
    })
    .sort((a, b) => +a?.id - +b?.id);
  return respData;
};

export const fetchContent = async (key) => {
  try {
    const fullURL = URLS.BASE_URL + key+"?per_page=100";
    const apiData = (await axios.get(fullURL)).data;
    let data = parseACF(apiData);
    return data;
  } catch (ex) {
    console.log(ex);
    return [];
  }
};

export const fetchContentModern = async (key) => {
  try {
    const fullURL = API_URL+key;
    const apiData = (await axios.get(fullURL)).data;
    return apiData;
  } catch (ex) {
    console.log(ex);
    return [];
  }
};
