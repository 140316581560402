<template>
  <div v-if="messages" class="container-fluid bg-gray py-5">
    <ul class="nav nav-tabs d-flex justify-content-center" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="first-tab" data-bs-toggle="tab" data-bs-target="#first" type="button"
          role="tab" aria-controls="first" aria-selected="false">
          <div class="h4 text-center">Message from District Governor</div>
        </button>
      </li>

      <li class="nav-item" role="presentation">
        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button"
          role="tab" aria-controls="contact" aria-selected="false">
          <div class="h4 text-center">Message from Vice District Governor</div>
        </button>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="home-tab">
        <section class="t-bq-section" id="dg">
          <div class="t-bq-wrapper t-bq-wrapper-boxed">
            <div class="t-bq-quote t-bq-quote-dg">
              <div class="t-bq-quote-dg-qmark"><span>&#10077;</span></div>
              <div class="t-bq-quote-dg-userpic"></div>
              <div class="t-bq-quote-dg-base">
                <blockquote class="t-bq-quote-dg-text" :cite="messages?.lionDistrictGovernor?.full_name">
                  {{ messages?.lionDistrictGovernor?.message }}
                </blockquote>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="home-tab">
        <section class="t-bq-section" id="second_vdg">
          <div class="t-bq-wrapper t-bq-wrapper-boxed">
            <div class="t-bq-quote t-bq-quote-second_vdg">
              <div class="t-bq-quote-second_vdg-qmark"><span>&#10077;</span></div>
              <div class="t-bq-quote-second_vdg-userpic"></div>
              <div class="t-bq-quote-second_vdg-base">
                <blockquote class="t-bq-quote-second_vdg-text" :cite="messages?.lionViceDistrictGovernor?.full_name">
                  {{ messages?.lionViceDistrictGovernor?.message }}
                </blockquote>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      messages: {}
    };
  },
  created() {
    this.$store.dispatch("fetchContentModern", "messages");
  },
  async mounted() {
    this.messages = this.$store.state.messages.reduce((acc, item) => {
      acc[item.key] = item;
      return acc;
    }, {});

  },
};
</script>

<style scoped lang="scss">
/* ========== 00. General Styling ========== */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

* {
  outline: 0;
  transition: all 0.3s ease;
}

body {
  margin: 0;
  margin-top: 60px;
  padding: 0;
  font-family: "Open Sans", "Noto Sans", HelveticaNeueCyr, Helvetica, sans-serif;
  line-height: 1.68em;
  color: #333333;
  background: #fafafa;
}

blockquote {
  padding: 0;
  margin: 0;
}

section.t-bq-section {
  padding: 30px;
  margin-bottom: 60px;
}

.h4 {
  font-size: 0.9em;
}

.nav-link {
  color: #5a97c1;
}

.t-bq-wrapper.t-bq-wrapper-boxed {
  max-width: 800px;
  margin: 0 auto;
}

.t-bq-wrapper.t-bq-wrapper-fullwidth {
  max-width: 100%;
}

.t-bq-wrapper .t-bq-quote {
  padding: 20px;
}

/* ========== 01. dg ========== */

.t-bq-quote-dg {
  position: relative;
  display: flex;
  flex-direction: row;
  min-height: 250px;
  background: #ffffff;
  padding: 0 !important;
  align-items: stretch;
  border-radius: 20px;
  box-shadow: 2px 2px 25px #cecece;

  & .t-bq-quote-dg-qmark {
    position: absolute;
    top: -30px;
    left: calc(50% - 30px);
    background: #ffffff;
    width: 60px;
    height: 60px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Impact, Garamond, Georgia, serif;
    font-size: 3em;
    color: #666666;
    line-height: 60px;
    user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    border-top: 2px solid #eeeeee;

    span {
      padding-top: 0.25em;
    }
  }

  & .t-bq-quote-dg-userpic {
    flex-basis: 150px;
    width: 200px;
    min-width: 200px;
    max-height: 320px;
    background: url("../../../public/images/leaders/current/dg.jpg") center center no-repeat;
    background-size: cover;
    border-radius: 20px 0 0 20px;
  }

  & .t-bq-quote-dg-base {
    flex-basis: calc(100% - 150px);
    padding: 60px 30px 45px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .t-bq-quote-dg-text {
      font-size: 0.85em;
      line-height: 1.8em;
      flex-grow: 0;
      flex-shrink: 0;

      &:after {
        content: "\2014\00A0" attr(cite);
        display: block;
        font-size: 0.8em;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        font-weight: bold;
        margin-top: 10px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .t-bq-quote-dg {
    flex-direction: column;

    & .t-bq-quote-dg-base {
      padding-top: 0;
    }

    & .t-bq-quote-dg-userpic {
      border-radius: 20px;
      height: 150px;
      margin: 40px auto 20px auto;
    }

    & .t-bq-quote-dg-base .t-bq-quote-dg-text {
      font-size: 1em;
    }
  }
}

.t-bq-quote-first_vdg {
  position: relative;
  display: flex;
  flex-direction: row;
  min-height: 250px;
  background: #ffffff;
  padding: 0 !important;
  align-items: stretch;
  border-radius: 20px;
  box-shadow: 2px 2px 25px #cecece;

  & .t-bq-quote-first_vdg-qmark {
    position: absolute;
    top: -30px;
    left: calc(50% - 30px);
    background: #ffffff;
    width: 60px;
    height: 60px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Impact, Garamond, Georgia, serif;
    font-size: 3em;
    color: #666666;
    line-height: 60px;
    user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    border-top: 2px solid #eeeeee;

    span {
      padding-top: 0.25em;
    }
  }

  & .t-bq-quote-first_vdg-userpic {
    flex-basis: 150px;
    width: 200px;
    min-width: 200px;
    max-height: 320px;
    background: url("../../../public/images/leaders/current/vdg.jpg") center center no-repeat;
    background-size: cover;
    border-radius: 20px 0 0 20px;
  }

  & .t-bq-quote-first_vdg-base {
    flex-basis: calc(100% - 150px);
    padding: 60px 30px 45px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .t-bq-quote-first_vdg-text {
      font-size: 0.85em;
      line-height: 1.8em;
      flex-grow: 0;
      flex-shrink: 0;

      &:after {
        content: "\2014\00A0" attr(cite);
        display: block;
        font-size: 0.8em;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        font-weight: bold;
        margin-top: 10px;
      }
    }
  }
}

.t-bq-quote-second_vdg {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 250px;
  background: #ffffff;
  padding: 0 !important;
  align-items: center;
  border-radius: 20px;
  box-shadow: 2px 2px 25px #cecece;

  & .t-bq-quote-second_vdg-qmark {
    position: absolute;
    top: -30px;
    left: calc(50% - 30px);
    background: #ffffff;
    width: 60px;
    height: 60px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Impact, Garamond, Georgia, serif;
    font-size: 3em;
    color: #666666;
    line-height: 60px;
    user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    border-top: 2px solid #eeeeee;

    span {
      padding-top: 0.25em;
    }
  }

  & .t-bq-quote-second_vdg-userpic {
    margin-top: 20px;
    flex-basis: 200px;
    width: 200px;
    min-width: 200px;
    max-height: 320px;
    background: url("../../../public/images/leaders/current/vdg.jpg") center center no-repeat;
    background-size: cover;
    // border-radius: 20px 0 0 20px;
    border-radius: 50px;
  }

  & .t-bq-quote-second_vdg-base {
    flex-basis: calc(100% - 150px);
    padding: 30px 30px 45px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .t-bq-quote-second_vdg-text {
      font-size: 0.85em;
      line-height: 1.8em;
      flex-grow: 0;
      flex-shrink: 0;

      &:after {
        content: "\2014\00A0" attr(cite);
        display: block;
        font-size: 0.8em;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        font-weight: bold;
        margin-top: 10px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .t-bq-quote-second_vdg {
    flex-direction: column;

    & .t-bq-quote-second_vdg-base {
      padding-top: 0;
    }

    & .t-bq-quote-second_vdg-userpic {
      border-radius: 20px;
      height: 150px;
      margin: 40px auto 20px auto;
    }

    & .t-bq-quote-second_vdg-base .t-bq-quote-second_vdg-text {
      font-size: 1em;
    }
  }
}

@media screen and (max-width: 768px) {
  .t-bq-quote-first_vdg {
    flex-direction: column;

    & .t-bq-quote-first_vdg-base {
      padding-top: 0;
    }

    & .t-bq-quote-first_vdg-userpic {
      border-radius: 20px;
      height: 150px;
      margin: 40px auto 20px auto;
    }

    & .t-bq-quote-first_vdg-base .t-bq-quote-first_vdg-text {
      font-size: 1em;
    }
  }
}

.bg-gray {
  background-color: #fafafa;
}
</style>