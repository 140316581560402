import Vue from 'vue'
import VueRouter from 'vue-router'
import Homepage from '../pages/Homepage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Homepage
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../pages/About.vue')
  }, {
    path: "/gallery",
    name: 'Gallery',
    component: () => import('../pages/Gallery.vue')
  }, {
    path: "/clubs",
    name: 'Clubs',
    component: () => import('../pages/Clubs.vue')
  }, {
    path: "/calendar",
    name: 'Calendar',
    component: () => import('../components/events/Calendar.vue')
  },
  {
    path: "/leo-resources",
    name: 'Leo Resources',
    component: () => import('../pages/resources/LeoResources.vue')
  },
  {
    path: "/development-teams",
    name: 'Development Teams',
    component: () => import('../pages/resources/DevelopmentTeams.vue')
  },
  {
    path: "/district-directory",
    name: 'District Directory',
    component: () => import('../pages/resources/DistrictDirectory.vue')
  },
  {
    path: "/nlf-membership",
    name: 'NLF Membership',
    component: () => import('../pages/resources/NLF/NLFMembership.vue')
  },
  {
    path: "/our-affiliations",
    name: 'Our Affiliations',
    component: () => import('../pages/resources/Affiliations.vue')
  },
  {
    path: "/leo-foundation",
    name: 'National Leo Foundation',
    component: () => import('../pages/resources/NLF/NLFDetails.vue')
  },
  {
    path: "/contact",
    name: 'Contact Us',
    component: () => import('../pages/ContactUs.vue')
  },
  , {
    path: "/events",
    name: 'Events',
    component: () => import('../pages/Events.vue')
  },
  , {
    path: "/blood-database",
    name: 'BloodDatabase',
    component: () => import('../pages/resources/BloodDatabase.vue')
  },
  , {
    path: "/donate",
    name: 'Donate',
    component: () => import('../pages/Donate.vue')
  },
  {
    path: "/teams",
    name: 'Teams',
    component: () => import('../pages/OurTeam.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})
// router.afterEach((to,from)=>{
//   if(  document.querySelector("#navbarSupportedContent").classList.contains("show")){
//      document.querySelector("#navbarSupportedContent").classList.toggle("show")
//   }
//   document.querySelector("#navbarSupportedContent").classList.toggle("show")
// })

export default router
