<template>
  <section class="teams_v2">
    <div class="container">
      <div class="title text-center">OUR CORE TEAM</div>
      <div class="row">
        <div class="teams" v-if="team.length > 0">
          <div class="member" v-for="(member, index) in team" :key="index">
            <div class="card border-0 text-center">
              <div class="card-image">
                <img :src="member?.image" />
              </div>
              <div class="card__title">
                {{ member?.fullName }}
              </div>
              <div class="card__description">
                {{ member?.designation }}
              </div>
              <div class="social">
                <a :href="member?.facebookURL"><i class="fab fa-facebook"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="py-4">
          <div class="text-center">Leader's list is empty.</div>
        </div>
        <div class="cta text-center">
          <router-link name="" id="" class="btn btn-primary btn-lg btn-cta" href="#" role="button" to="/teams">Load More
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import constants from '@/constants/constants';

export default {
  computed: {
    team() {
      let teamInfo = this.$store.state.leaders;

      const finalList = []
      constants.sortedDesignations.forEach((role) => {
        const item = teamInfo.find(item => item.designation === role && item.lionistic_year === constants.lionistic_year)
        if (item) finalList.push(item)
      })

      return finalList.slice(0, 6)
    },
  },
  created() { },
  mounted() {
    this.$store.dispatch("fetchContentModern", "leaders");
  },
  data() {
    return {
      loaded: false,
    };
  },
};
</script>

<style scoped lang="scss">
.teams_v2 {
  padding: 50px 0px;

  .title {
    font-weight: bold;
  }

  .teams {
    padding: 50px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;

    .card {
      min-width: 300px;
      min-height: 380px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 30px 0px;
      // box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;

      &:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

        .social a i {
          opacity: 1;
        }
      }

      .card__title {
        padding: 20px 0px 0px 0px;
        font-weight: bold;
        font-size: 1em;
        color: #667380;

        &:hover {
          color: #0b265a;
        }
      }

      .card__description {
        font-size: 0.9em;
        color: #757678;
      }

      .social a i {
        font-size: 2em;
        opacity: 0;
        color: #667380;

        // color: #5a97c1;
        &:hover {
          color: #0b80f6;
        }
      }

      img {
        width: 200px;
        height: 200px;
        border-radius: 200px;
        object-fit: cover;
        text-align: center;
        object-position: top;
        margin: 10px 0px;
        transition: all 0.2s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}
</style>