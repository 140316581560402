<template>
  <section class="about__us parallax">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-md-6 col left">
          <div class="title h1">Who we are ?</div>
          <div class="subtitle">
            {{ config?.who_we_are?.description }}
          </div>

          <div class="cta">
            <router-link name="" id="" class="btn btn-primary btn-lg btn-cta" href="#" role="button" to="/about">Read
              More
            </router-link>
          </div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="image image-wrapper">
            <div class="shine">
              <img src="images/misc/who_we_are.jpg" class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters({
      config: 'getConfig'
    })
  },
};
</script>

<style lang="scss">
.about__us {
  // background-color: #fafafa;
  // display: flex;
  padding: 50px 0px;

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    .title {
      font-size: 2em;
      text-transform: uppercase;
    }

    .btn-cta {
      background-color: #ff6600;
      padding: 10px;
      border: none;

      &:hover {
        background-color: #00bfff;
      }
    }

    .left {
      display: flex;
      flex-direction: column;

      gap: 20px;
      padding: 20px;

      .bordered__ {
        background-color: #cc0033;
        height: 3px;
        width: 20px;
        margin: 5px 0px;
      }
    }
  }
}
</style>