<template>
  <section class="join_with_us section-padding">
    <div class="container">
      <div class="left">
        <div class="title">Join with Us</div>
        <div class="subtitle">
          You can connect with us via our social media links.
        </div>
        <div class="join d-flex gap-3">
          <a href="https://www.facebook.com/LDC325JNEPAL">
            <i class="fa fab fa-facebook fa-2x"></i>
          </a>
          <a href="#">
            <i class="fa fab fa-twitter fa-2x"></i>
          </a>
          <a href="#">
            <i class="fa fab fa-instagram fa-2x"></i>
          </a>
          <a href="#">
            <i class="fa fa-cloud fa-2x"></i>
          </a>
          <a href="#">
            <i class="fa fab fa-linkedin fa-2x"></i>
          </a>
          <a href="#">
            <i class="fa fab fa-youtube fa-2x"></i>
          </a>
        </div>
        <div class="py-2">
          <b>For visit, we are located at,</b>
          <br />
          {{ config.district_address?.description }}
          <br /><br />
          <b> Feel Free to Contact Us :
          </b>
          <br />
          Office: {{ config.district_address?.description }} <br />
          {{ config.district_president_name?.description }} (District President): {{
            config.district_president_phone?.description }}<br />
          {{ config.district_admin_name?.description }} (Secretariat Coordinator): {{
            config.district_admin_phone?.description }}
        </div>
      </div>
      <div class="right">
        <div class="image_one shine">
          <img
            src="https://preview.colorlib.com/theme/chariter/assets/img/gallery/xjoining1.jpg.pagespeed.ic.45qc-jRgkQ.webp"
            alt="" srcset="" class="img-fluid" />
        </div>
        <div class="image_two shine">
          <img src="../../../public/images/logos/current/logo.png" alt="" srcset="" class="img-fluid" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters({
      config: 'getConfig'
    })
  },
  mounted() {
    console.log(this.config);
  }
}
</script>

<style lang="scss" scoped>
.join_with_us {
  padding: 80px 0px;
  background-color: #fafafa;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;

    .left {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex: 1;
      flex-grow: 1;
      min-width: 400px;
    }

    .btn {
      border: 1px solid #444;
    }

    .right {
      flex: 1;
      display: flex;
      gap: 20px;
      flex-direction: row;
      align-items: center;

      .image_1,
      .image_2 {
        width: 200px;
        height: 200px;
        object-fit: contain;
      }
    }
  }
}
</style>