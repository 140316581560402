<template>
  <div class="glow">
    <h1>
      <span class="one">LEAD </span>
      <!-- <span class="two">o</span -->
      <span class="three">WITH </span>
      <span class="four">SMILE </span>
      <!-- <span class="one"></span> -->
      <!-- <span class="six">o</span
      >
      <span class="three">R</span> <span class="eight">M</span
      ><span class="nine">O</span><span class="ten">R</span
      ><span class="eleven">E</span> -->
    </h1>

    <!-- <img
      src="https://images.unsplash.com/photo-1546707012-c46675f12716?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1349&q=80"
      alt="Sebastian Ervi"
    /> -->
    <img
      src="https://images.unsplash.com/photo-1470229538611-16ba8c7ffbd7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.glow {
  background-color: #212121;
  color: #4db1bc;
  background-attachment: fixed;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;

  img {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    grid-column: 1;
    grid-row: 1;
    opacity: 0.3;
    background-color: #000;
    z-index: 0;
  }

  h1 {
    margin: 0;
    grid-column: 1;
    grid-row: 1;
    z-index: 1;
    font-family: "Pacifico", sans-serif;
    font-size: 10rem;
    text-transform: uppercase;
    animation: glow 1s ease-in-out infinite alternate;
    text-align: center;
  }

  a {
    font-family: "Pacifico", sans-serif;
    color: #4db1bc;
    grid-column: 1;
    grid-row: 1;
    align-self: end;
    justify-self: center;
    padding-bottom: 1rem;
  }

  @keyframes glow {
    from {
      text-shadow: 0 0 10px #2d9da9;
    }

    to {
      text-shadow: 0 0 40px #34b3c1, 0 0 10px #4dbbc7;
    }
  }
}

@media screen and (max-width: 768px) {
  .glow {
    h1 {
      margin: 0;
      grid-column: 1;
      grid-row: 1;
      z-index: 1;
      font-family: "Pacifico", sans-serif;
      font-size: 7rem;
      text-transform: uppercase;
      animation: glow 1s ease-in-out infinite alternate;
      text-align: center;
    }
  }
}
</style>