<template>
  <div id="app">
    <div id="nav">
      <Header />
    </div>
    <router-view />
    <Footer />
    <Bottombar />
  </div>
</template>

<script>
import "../public/style.scss";

import FooterVue from "./components/common/Footer.vue";
import Header from "./components/common/Header.vue";
import Footer from "./components/common/Footer.vue";
import Bottombar from "./components/common/BottomBar.vue";

export default {
  components: {
    FooterVue,
    Header,
    Footer,
    Bottombar
  },
  created() {
    this.$store.dispatch("fetchConfig");
    this.$store.dispatch("fetchContentModern", "messages");
  }
};
</script>