<template>
  <section class="stats">
    <div class="container">
      <div class="items">
        <div class="item">
          <div class="icon">
            <img
              src="https://cdn2.iconfinder.com/data/icons/flat-pack-1/64/Team-512.png"
              alt=""
              srcset=""
            />
          </div>
          <div class="right">
            <div class="title">1000 +</div>
            <div class="subtitle">Leaders</div>
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <img
              src="https://cdn2.iconfinder.com/data/icons/icontober/64/Inkcontober_United-512.png"
              alt=""
              srcset=""
            />
          </div>
          <div class="right">
            <div class="title">100 +</div>
            <div class="subtitle">Works</div>
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <img
              src="https://cdn1.iconfinder.com/data/icons/miscellaneous-205-line/128/district_country_neighborhood_shire_area_sector_zone_locality_region_territory-512.png"
              alt=""
              srcset=""
            />
          </div>
          <div class="right">
            <div class="title">6 +</div>
            <div class="subtitle">Regions</div>
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <img
              src="https://cdn2.iconfinder.com/data/icons/leto-teamwork/64/__handshake_team_partners-512.png"
              alt=""
              srcset=""
            />
          </div>
          <div class="right">
            <div class="title">10 +</div>
            <div class="subtitle">Partners</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.stats {
  // height: 200px;
  padding: 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../public/images/misc/bg.png");

  min-height: 250px;
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;

  .items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
    justify-content: space-between;
    align-items: center;

    .icon {
      img {
        transition: all 0.2s ease-in-out;
        width: 100%;
        height: 70px;
        filter: brightness(0) invert(1);

        &:hover {
          width: 100%;
          height: 80px;
        }
      }
    }

    .item {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: center;

      .right {
        .title {
          font-size: 2em;
          color: #fff;
        }

        .subtitle {
          font-size: 1.25em;
          color: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .item {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }
}
</style>
